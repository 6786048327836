<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          color="#000"
          @click="showForm = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          Create Type
          <br>
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="documentTypes"
        :search="search"
        class="elevation-1 table-projects"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.type | typeUpdate }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <create-document-type
      v-if="showForm"
      @closed="showForm = false"
      @document-type-created="getDocumenyTypes()"
    />
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
  import CreateDocumentType from 'src/views/dashboard/settings/CreateDocumentTypes.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';

  export default {
    name: 'DocumentTypes',
    components: {
      'create-document-type': CreateDocumentType,
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      typeUpdate (val) {
       if (val === 'normal') {
        return 'Normal';
       }
       return 'High Risk';
      },
    },
    data () {
      return {
        showForm: false,
        loading: false,
        headers: [
          { text: 'Document Name', align: 'start', value: 'name' },
          { text: 'Type', value: 'type' },
          { text: 'Description', value: 'description' },
          { text: 'Added On', value: 'created_at' },
        ],
        search: '',
      };
    },
    computed: {
      documentTypes () {
        return this.$store.getters['featuresettings/getDocumentTypesList'];
      },
    },
    async mounted () {
     await this.getDocumenyTypes();
    },
    methods: {
      async getDocumenyTypes () {
        this.loading = true;
        await this.$store.dispatch('featuresettings/fetchDocumentTypes').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.v-card {
  margin-top: 0px !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
