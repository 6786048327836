<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          color="#000"
          @click="showForm = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          Add New
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="categoryTable.headers"
        :items="workerCategoryList"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        class="elevation-1 clickable table-projects"
        mobile-breakpoint="100"
        :search="search"
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <v-btn
                icon
                small
                @click.stop
                @click="expand(!isExpanded)"
              >
                <v-icon
                  v-if="!isExpanded"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="isExpanded">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card>
              <v-toolbar
                color="#37474F"
                dark
              >
                <v-toolbar-title class="my-tool-bar-title-style">
                  Document Types
                </v-toolbar-title>
              </v-toolbar>
              <v-list>
                <v-list-item-content class="ml-3">
                  <v-list-item-title class="my-title-style">
                    <template
                      v-for="(doc, i) in item.documents"
                    >
                      <v-chip
                        :key="i"
                        :color="isDocMandatory(doc)"
                        outlined
                        class="document-chip"
                      >
                        {{ doc.name }}
                        <br><font class="type-style">
                          [ {{ doc.type }} Type ]
                        </font>
                      </v-chip>
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
                <font class="mandatory-Doc-style">
                  Mandatory documents are highlighted in red*.
                </font>
              </v-list>
            </v-card>
          </td>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <create-worker-category
      v-if="showForm"
      @closed="showForm = false"
      @category-created="getWorkerCategory()"
    />
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
  import CreateWorkerCategory from 'src/views/dashboard/settings/CreateWorkerCategory.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';

  export default {
    name: 'WorkerCategories',
    components: {
      'create-worker-category': CreateWorkerCategory,
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    data () {
      return {
        showForm: false,
        loading: false,
        selectedCardIndex: null,
        expanded: [],
        singleExpand: true,
        categoryTable: {
          headers: [
            { text: 'Category Name', align: 'left', value: 'name' },
            { text: 'Description', value: 'description' },
            { text: 'Added On', value: 'created_at' },
            { text: 'Documents', value: 'data-table-expand' },
          ],
        },
        search: '',
      };
    },
    computed: {
      workerCategoryList () {
        return this.$store.getters['featuresettings/getWorkerCategoryList'];
      },
    },
    async mounted () {
     await this.getWorkerCategory();
    },
    methods: {
      async getWorkerCategory () {
        this.loading = true;
        await this.$store.dispatch('featuresettings/fetchWorkerCategoryList').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      isDocMandatory (doc) {
        if (doc.mandatory === true) {
          return '#C62828';
        }
        return '#37474f';
      },
    },
  };
</script>
<style scoped>
.mandatory-Doc-style {
  margin-left: 20px;
  font-size: 12px;
  font-style: italic;
  color: #37474F;
}
.type-style {
  color:black;
  font-weight: bold;
  margin-left: 5px;
  text-transform: capitalize;
}
 .v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table {
  background-color: #ECEFF1;
}
.my-toolbar-style {
  color: #37474F;
  font-weight: bold;
}
.my-tool-bar-title-style {
 font-size: 18px;
}
.my-toolbar-style {
  padding-left: 0px;
}
.strong-style {
  color: #37474F;
  font-weight: bold;
}
.my-title-style {
  font-size: 14px !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.option-style {
  color: #37474F;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}
.font-my-style {
  font-weight: bold;
}
.document-chip {
  margin-left: 5px;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.v-card {
  margin-top: 0px !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
