import { render, staticRenderFns } from "./CreateQuestionnaire.vue?vue&type=template&id=4944b603&scoped=true"
import script from "./CreateQuestionnaire.vue?vue&type=script&lang=js"
export * from "./CreateQuestionnaire.vue?vue&type=script&lang=js"
import style0 from "./CreateQuestionnaire.vue?vue&type=style&index=0&id=4944b603&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4944b603",
  null
  
)

export default component.exports