<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style pt-3">
      Safety 🛡️
    </h2>
    <p class="mt-0 mb-0 sub-title-style">
      Explore Your Safety Settings
    </p>
    <v-tabs
      v-model="tabs"
      class="mt-5"
    >
      <v-tab>
        <v-icon class="mr-2">
          mdi-file-document-outline
        </v-icon> Document Types
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-account-group-outline
        </v-icon> Worker Categories
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-tooltip-question-outline
        </v-icon>  Questionnaire
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item><document-types /></v-tab-item>
      <v-tab-item><worker-category /></v-tab-item>
      <v-tab-item><questionnaire /></v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import WorkerCategory from 'src/views/dashboard/settings/WorkerCategories.vue';
import DocumentTypes from 'src/views/dashboard/settings/DocumentTypes.vue';
import Questionnaire from 'src/views/dashboard/settings/Questionnaire.vue';

  export default {
    name: 'SettingsLandingPage',
    components: {
      'worker-category': WorkerCategory,
      'document-types': DocumentTypes,
      Questionnaire,
    },
    data: () => ({
      selectedItem: -1,
      workerCategories: false,
      showDocumentTypes: true,
      showQuestionnaire: false,
      tabs: null,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
     methods: {
      getWorkerCategories () {
        this.showDocumentTypes = false;
        this.showQuestionnaire = false;
        this.workerCategories = true;
      },
      getDocumentTypes () {
        this.workerCategories = false;
        this.showQuestionnaire = false;
        this.showDocumentTypes = true;
      },
      getQuestionnaires () {
        this.showDocumentTypes = false;
        this.workerCategories = false;
        this.showQuestionnaire = true;
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.title-style{
  font-size: 36px;
  font-weight: 600;
}
.sub-title-style {
  color: #aaa !important;
}
</style>
